import { Injectable } from "@angular/core";
import { Http } from '@angular/http';
import { Observable, Subject } from "rxjs";


@Injectable()
export class OrderlineService {

  constructor(
    public http: Http,
  ) { 
  }

  create(params):Observable<any> {
    return Observable.create(observer => {
      this.http.post('/orderlines', params).subscribe(result => {
        observer.next(result);
        observer.complete();
      })
    })
  }

 edit(orderline_id, params):Observable<any> {
    return Observable.create(observer => {
      this.http.put(`/orderlines/${orderline_id}`, params).subscribe(result => {
        observer.next(result);
        observer.complete();
      })
    })
  }

  destroy(orderline_id):Observable<any> {
    return Observable.create(observer => {
      this.http.delete(`/orderlines/${orderline_id}`).subscribe(result => {
        observer.next(result);
        observer.complete();
      })
    })
  }
}