import { Injectable } from "@angular/core";
import { Http } from '@angular/http';
import { Observable } from "rxjs";


@Injectable()
export class ProductService {

  constructor(
    public http: Http,
  ) { 
  }

  get(id):Observable<any> {
    return Observable.create(observer => {
      this.http.get(`/product/${id}`).subscribe(result => {
        observer.next(result);
        observer.complete();
      })
    })
  }
}