import { Component, OnInit } from "@angular/core";
import { SettingService } from "../services/setting.service";
import templateString from './policy.page.html';

@Component({
  selector: 'app-policy',
  template: templateString
})
export class PolicyComponent implements OnInit {
  public privacy_policy:any;

  constructor(
    public settingService: SettingService,
  ){}

  ngOnInit(): void {
    this.settingService.privacyPolicy().subscribe(result => {
      this.privacy_policy = result.json();
    })
  }
}