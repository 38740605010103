import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router
import { ConfirmComponent } from './pages/confirm.page';
import { HomeComponent } from './pages/home.page';
import { PolicyComponent } from './pages/policy.page';
import { ThankyouComponent } from './pages/thankyou.page';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'confirm', component: ConfirmComponent },
  { path: 'order/:id/payment_finished', component: ThankyouComponent},
  { path: 'policy', component: PolicyComponent}
]; // sets up routes constant where you define your routes

// configures NgModule imports and exports
@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }