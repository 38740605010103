import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import templateString from './message.modal.html';


@Component({
  selector: 'app-message-modal',
  template: templateString
})
export class MessageModalComponent implements OnInit {
  @Output() onClosed = new EventEmitter<string>();

  public message: any;

  constructor(    
    private readonly bsModalRef: BsModalService
  ) {
    
  }

  ngOnInit(): void {
  }

  handleCancel() {
    this.bsModalRef.hide();
    this.onClosed.emit('');
  }
}