import { ViewportScroller } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import templateString from './categories.html';

@Component({
  selector: 'app-categories-menu',
  template: templateString
})
export class CategoriesComponent implements OnInit {
  @Input() categories;


  constructor(
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit() {
  }

  onClick(elementId: string): void { 
    this.viewportScroller.scrollToAnchor(elementId);
  }
}