import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { OrderService } from "../services/order.service";
import { SettingService } from "../services/setting.service";
import templateString from './thankyou.page.html';

@Component({
  selector: 'app-thankyou',
  template: templateString
})
export class ThankyouComponent implements OnInit {
  public date: Date = null;
  public settings:any;
  public status: false;
  public loaded = false;

  constructor(
    public orderService: OrderService,
    public settingService: SettingService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => {
      const id = this.route.snapshot.paramMap.get("id")
      this.orderService.time(id).subscribe(result => {
        const data = result.json();
        this.date = new Date(data['day'].replace(/-/g, "/"));

      })

      this.orderService.status(id).subscribe(result => {
        this.status = result.json();
        this.loaded = true;
      })
    });

    this.settingService.get().subscribe(result => {
      this.settings = result.json();
    })
  }

  ngOnInit(): void {
  }
}