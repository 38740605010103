import { Pipe, PipeTransform } from '@angular/core';
import { resolveTripleslashReference } from 'typescript';

@Pipe({name: 'customTime'})
export class customTimePipe implements PipeTransform {
  transform(value: number): string{
    if(!value) {
      return "";
    }
    
    const string = value.toString();
    const parts = string.split(':')
    const hours = parts[0];
    let minutes = parts[1]
    if(minutes == "00") {
      minutes = ""
    }
    return hours + 'u' + minutes;
  }
}