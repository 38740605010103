import { NumberSymbol, ViewportScroller } from "@angular/common";
import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { Component, OnInit } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { MessageModalComponent } from "../modals/message.modal";
import { SlotModalComponent } from "../modals/slot.modal";
import { GeneralService } from "../services/general.service";
import { MenuService } from "../services/menu.service";
import { MessageService } from "../services/message.service";
import { OrderService } from "../services/order.service";
import templateString from './home.page.html';

@Component({
  selector: 'app-home',
  template: templateString
})
export class HomeComponent implements OnInit {
  public categories: Array<any>;
  public cart_size: Number;  

  constructor(
    public menuService: MenuService,
    public generalService: GeneralService,
    private readonly modalService: BsModalService,
    private viewportScroller: ViewportScroller,
    public messageService: MessageService,
    public orderService: OrderService
  ){
    
  }

  ngOnInit(): void {
    this.validSlot();
    this.loadMenu();
    this.loadMessages();
    
    //this.openSlotModal();
  }

  loadMenu() {
    this.menuService.get().subscribe(result => {
      this.categories = result.json();
    });
  }

  loadMessages() {
    this.messageService.get().subscribe(result => {
      const messages = result.json();
      if(messages.length > 0) {
        this.openMessageModal(messages[0]);
      } else {
        this.openSlotModal();
      }
    })
  }

  validSlot() {
    this.orderService.valid().subscribe(result => {
      console.log("valid", result.json());
    })
  }

  openMessageModal(message) {
    
    const initialState = {
      message
    }
    const modalref = this.modalService.show(MessageModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-md',
        backdrop: 'static',
        initialState
      })
    )
    modalref.content.onClosed.subscribe(result => {
      const self = this;
      setTimeout(function(){
        self.openSlotModal();
      }, 2000);
    })
  }

  openSlotModal() {
    const modalref = this.modalService.show(SlotModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-md',
      })
    )
  }

  onClick(elementId: string): void { 
    this.viewportScroller.scrollToAnchor(elementId);
  }

  cartChanged(number) {
    this.cart_size = number;
  }
}