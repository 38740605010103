import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import templateString from './cart.html';
import { OrderService } from '../services/order.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OrderLineModalComponent } from '../modals/order-line.modal';
import { GeneralService } from '../services/general.service';

@Component({
  selector: 'app-cart',
  template: templateString
})
export class CartComponent implements OnInit {
  @Input() editable: string;
  public order: any;
  @Output() cartChanged = new EventEmitter<number>();

  constructor(
    public orderService: OrderService,
    private readonly modalService: BsModalService
  ) { }

  ngOnInit() {
    this.getCart();

    this.orderService.orderChanged$.subscribe(result => {
      this.getCart();
    })
  }

  getCart() {
    this.orderService.get().subscribe(result => {
      this.order = result.json();
      this.cartChanged.emit(this.orderLinesLength(this.order));
    })
  }

  orderLinesLength(order) {
    let length = 0;
    this.order['order_lines'].forEach(line => {
      length += line['amount'];
    });
    return length;
  }

  editOrderline(event, orderline) {
    event.preventDefault();

    const initialState = {
      type: "EDIT",
      orderline: orderline
    }

    const modalref = this.modalService.show(OrderLineModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-md',
        initialState
      })
    )
  }

  clear(event) {
    event.preventDefault();
    this.orderService.destroy().subscribe(result => {
      this.orderService.orderChangedSource.next();
    })
  }
}