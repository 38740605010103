import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { SlotModalComponent } from "../modals/slot.modal";
import { GeneralService } from "../services/general.service";
import { OrderService } from "../services/order.service";
import templateString from './confirm.page.html';

@Component({
  selector: 'app-confirm',
  template: templateString
})
export class ConfirmComponent implements OnInit {
  

  ngOnInit(): void {
    
  }
}