import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { OrderService } from "../services/order.service";
import { SlotService } from "../services/slots.service";
import templateString from './slot.html';

@Component({
  selector: 'app-slot',
  template: templateString
})
export class SlotComponent implements OnInit {
  @Output() slotSelected = new EventEmitter<string>();
  public slots = [];
  public slot;
  public slotInterval:any;
  public loaded = false;

  constructor(
    private readonly slotService: SlotService,
    private readonly orderService: OrderService
  ){}

  ngOnInit(): void {
    this.getSlots();
    this.getOrder();

    this.slotInterval = setInterval(() => {
      this.getSlots();
    }, 5000)
  }

  ngOnDestroy() {
    clearInterval(this.slotInterval);
  }

  getOrder() {
    this.orderService.get().subscribe(result => {
    })
  }

  getSlots() {
    this.slotService.get().subscribe(result => {
      this.slots = result.json();
      this.setSlotFromBackend(this.slots);
      this.loaded = true;
    })
  }

  setSlotFromBackend(slots) {
    slots.forEach(slot => {
      if(slot.selected) {
        this.slot = slot;
      } 
    });
  }

  setSlot(slot) {
    this.slot = slot;
    this.saveSlot();
    this.slotSelected.emit();
  }

  close(event) {
    this.slotSelected.emit();
  }

  isSelected(slot) {
    return this.slot == slot;
  }

  saveSlot() {
    this.orderService.update({slot: this.slot.start_time, hour: this.slot.hour, minutes: this.slot.minutes}).subscribe(result => {
    })
  }
}