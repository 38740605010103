import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';

import { AppComponent } from './app.component';
import { MenuService } from './services/menu.service';
import { CategoriesMobileComponent } from './components/categories-mobile';
import { CategoriesComponent } from './components/categories';
import { MenuComponent } from './components/menu';
import { CartComponent } from './components/cart';
import { OrderService } from './services/order.service';
import { ModalModule } from 'ngx-bootstrap/modal/'
import { OrderLineModalComponent } from './modals/order-line.modal';
import { ProductService } from './services/product.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderlineService } from './services/orderline.service';
import { AppRoutingModule } from './routing.module';
import { HomeComponent } from './pages/home.page';
import { ConfirmComponent } from './pages/confirm.page';
import { APP_BASE_HREF } from '@angular/common';
import { SlotComponent } from './components/slot';
import { OrderFormComponent } from './components/order-form';
import { SlotService } from './services/slots.service';
import { SlotModalComponent } from './modals/slot.modal';
import { ThankyouComponent } from './pages/thankyou.page';
import { RouterModule } from '@angular/router';
import { PolicyComponent } from './pages/policy.page';
import { OpeningTimeService } from './services/openingtimes.service';
import { customTimePipe } from './pipes/time.pipe';
import { SettingService } from './services/setting.service';
import { pricePipe } from './pipes/price.pipe';
import { MessageService } from './services/message.service';
import { MessageModalComponent } from './modals/message.modal';

@NgModule({
  declarations: [
    AppComponent,
    CategoriesMobileComponent,
    CategoriesComponent,
    MenuComponent,
    CartComponent,
    OrderLineModalComponent,
    SlotModalComponent,
    MessageModalComponent,
    HomeComponent,
    PolicyComponent,
    ThankyouComponent,
    ConfirmComponent,
    SlotComponent,
    OrderFormComponent,
    customTimePipe,
    pricePipe
  ],
  imports: [
    BrowserModule,
    HttpModule,
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule  
  ],
  providers: [
    MenuService,
    OrderService,
    OrderlineService,
    ProductService,
    OpeningTimeService,
    SlotService,
    SettingService,
    MessageService,
    {provide: APP_BASE_HREF, useValue: '/'}
  ],
  entryComponents: [
    OrderLineModalComponent,
    SlotModalComponent,
    MessageModalComponent,
    CategoriesMobileComponent,
    CategoriesComponent,
    MenuComponent,
    CartComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
