import { Component, OnInit, Input } from '@angular/core';
import templateString from './order-line.modal.html';
import { ProductService } from '../services/product.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OrderService } from '../services/order.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { OrderlineService } from '../services/orderline.service';

@Component({
  selector: 'app-order-line-modal',
  template: templateString
})
export class OrderLineModalComponent implements OnInit {
  public product_id: any;
  public product;
  public type: string;
  public orderline;
  public form: FormGroup;
  public options = [];
  public category_options = [];
  public option_required = false;
  public counter: number = 1;

  constructor(
    private readonly bsModalRef: BsModalService,
    private readonly productService: ProductService,
    private readonly orderService: OrderService,
    private readonly orderlineService: OrderlineService,
    private readonly formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.formSetup();

    if(this.type == "EDIT") {
      this.product_id = this.orderline.product.id;
      this.counter = this.orderline.amount;
      this.setOptions();
      this.form.get('remark').setValue(this.orderline['remark']);
    }

    this.getProduct();
    
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      remark: []
    })
  }

  getProduct() {
    this.productService.get(this.product_id).subscribe(result => {
      this.product = result.json();
    })
  }

  optionClicked(event, option_id, type) {
    if(type == 'PRODUCT') {
      this.toggleOption(option_id, this.options);
    }

    if(type == 'CATEGORY') {
      this.toggleOption(option_id, this.category_options);
    }
  }

  toggleOption(option_id, array) {
    const index = array.indexOf(option_id);
    if(index > -1) {
      array.splice(index, 1);
    } else {
      array.push(option_id);
    }
    console.log(array);
  }

  setOptions() {
    this.orderline.category_options.forEach(option => {
      this.category_options.push(option.id);
    });

    this.orderline.product_options.forEach(option => {
      this.options.push(option.id);
    });
  }

  isChecked(id, array) {
    const index = array.indexOf(id);
    return (index > -1);
  }

  remove(event) {
    event.preventDefault();
    this.orderlineService.destroy(this.orderline.id).subscribe(result => {
      this.orderService.orderChangedSource.next();
      this.bsModalRef.hide();
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const params = this.form.value;
    params['category_options_ids'] = this.category_options;
    params['product_options_ids'] = this.options;
    params['amount'] = this.counter;

    if(this.product.option_required && this.options.length === 0 && this.category_options.length === 0 ) {
      this.option_required = true;
      return;
    } else {
      this.option_required = false;
    }

    if(this.type == 'NEW') {
      params['product_id'] = this.product['id'];
      this.orderlineService.create(params).subscribe(result => {
        this.orderService.orderChangedSource.next();
        this.bsModalRef.hide();
      })
    }
    
    if(this.type == 'EDIT') {
      this.orderlineService.edit(this.orderline['id'],params).subscribe(result => {
        this.orderService.orderChangedSource.next();
        this.bsModalRef.hide();
      })
    }
  }

  handleCancel() {
    this.bsModalRef.hide();
  }

  plus() {
    this.counter = this.counter + 1;
  }

  min() {
   if(this.counter === 1) {
     return;
   }
   this.counter = this.counter - 1;
  }
}