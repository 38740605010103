import { Component, OnInit, Input } from '@angular/core';
import templateString from './menu.html';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OrderLineModalComponent } from '../modals/order-line.modal';
import { GeneralService } from '../services/general.service';
import Vegi from '../images/vegi.png'


@Component({
  selector: 'app-menu',
  template: templateString
})
export class MenuComponent implements OnInit {
  @Input() categories;
  public vegi_src:string;

  constructor(
    private readonly modalService: BsModalService
  ) { }

  ngOnInit() {
    this.vegi_src = Vegi;
  }

  openOrderLineModal(event, product) {
    event.preventDefault();

    const initialState = {
      type: "NEW",
      product_id: product.id
    }

    const modalref = this.modalService.show(OrderLineModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-md',
        initialState
      })
    )
  }
}