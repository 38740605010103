import { ViewportScroller } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import templateString from './categories-mobile.html';

@Component({
  selector: 'app-categories-menu-mobile',
  template: templateString
})
export class CategoriesMobileComponent implements OnInit {
  @Input() categories;

  constructor(
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit() {
  }

  onClick(elementId: string): void { 
    this.viewportScroller.scrollToAnchor(elementId);
  }

}