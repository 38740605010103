import { Component, OnInit } from '@angular/core';
import templateString from './app.component.html'
import { GeneralService } from './services/general.service';
import { MessageService } from './services/message.service';
import { OpeningTimeService } from './services/openingtimes.service';
import { SettingService } from './services/setting.service';

@Component({
  selector: 'hello-angular',
  template: templateString
})
export class AppComponent implements OnInit {
  public days = ['maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag', 'zondag'];
  public opening_times: Array<any> = [];
  public settings:any;
  constructor(
    public openingtimeService: OpeningTimeService,
    public settingService: SettingService,
    public messageService: MessageService,
    public generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.openingtimeService.get().subscribe(result => {
      this.opening_times = result.json();
    })

    this.settingService.get().subscribe(result => {
      this.settings = result.json();
    })
  }

  getTimesFor(day):Array<any> {
    return this.opening_times.filter(function (time) { return (time.day === day)})
  }
}
