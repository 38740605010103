import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  constructor() {

  }

  public static BsModalOptions(input: any = {}) {
    return input

  }
}