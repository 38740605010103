import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'price'})
export class pricePipe implements PipeTransform {
  transform(value: number): string{
    if(!value) {
      return '';
    }
    
    return '€ ' + parseFloat(value.toString()).toFixed(2).replace('.', ',');
  }
}