import { Injectable } from "@angular/core";
import { Http } from '@angular/http';
import { Observable } from "rxjs";


@Injectable()
export class SettingService {

  constructor(
    public http: Http,
  ) { 
  }

  get():Observable<any> {
    return Observable.create(observer => {
      this.http.get(`/settings`).subscribe(result => {
        observer.next(result);
        observer.complete();
      })
    })
  }

  privacyPolicy():Observable<any> {
    return Observable.create(observer => {
      this.http.get(`/privacy_policy`).subscribe(result => {
        observer.next(result);
        observer.complete();
      })
    })
  }
}