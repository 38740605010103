import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalService } from "ngx-bootstrap/modal";
import { SlotModalComponent } from "../modals/slot.modal";
import { GeneralService } from "../services/general.service";
import { OrderService } from "../services/order.service";
import templateString from './order-form.html';

@Component({
  selector: 'app-order-form',
  template: templateString
})
export class OrderFormComponent implements OnInit {
  public form: FormGroup;
  public error: boolean = false;
  public order: string;
  public date: Date = null;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly orderService: OrderService,
    public generalService: GeneralService,
    private readonly modalService: BsModalService,
  ){}

  
  ngOnInit(): void {
    this.formSetup();
    this.getOrder();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      full_name: ['',Validators.required],
      phone: ['',[Validators.required, Validators.minLength(6), Validators.maxLength(16), Validators.pattern(/^-?([\d\s]*)?$/) ]],
      remark: [],
      policy: ['', Validators.requiredTrue]
    })
  }

  getOrder() {
    this.orderService.get().subscribe(result => {
      this.order = result.json();
      if(this.order['day']) {
        this.date = new Date(this.order['day'].replace(/-/g, "/"));
      }
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    this.orderService.update(this.form.value).subscribe(result => {
      this.placeOrder();
    }) 
  }

  placeOrder() {
    this.orderService.valid().subscribe(result => {
      if(result.json() == true) {
        window.location.href = '/orders/place'
      } else {
        this.error = true 
      }
    }, error => {
      this.error = true 
    }) 
  }

  openSlotModal(event) {
    event.preventDefault();
    const modalref = this.modalService.show(SlotModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-md',
      })
    )

    modalref.content.slotChanged.subscribe(result => {
      this.order['day'] = ""
      setTimeout(()=>{                      
        this.getOrder();
      }, 1000);
    })
  }
}