import { Injectable } from "@angular/core";
import { Http } from '@angular/http';
import { Observable, Subject } from "rxjs";


@Injectable()
export class OrderService {
  public readonly orderChangedSource = new Subject<any>();
  orderChanged$ = this.orderChangedSource.asObservable();

  constructor(
    public http: Http,
  ) { 
  }

  get():Observable<any> {
    return Observable.create(observer => {
      this.http.get('/orders').subscribe(result => {
        observer.next(result);
        observer.complete();
      })
    })
  }

  valid():Observable<any> {
    return Observable.create(observer => {
      this.http.get('/orders/valid').subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      })
    })
  }

  time(id):Observable<any> {
    return Observable.create(observer => {
      this.http.get(`/orders/${id}/time`).subscribe(result => {
        observer.next(result);
        observer.complete();
      })
    })
  }

  status(id):Observable<any> {
    return Observable.create(observer => {
      this.http.get(`/orders/${id}/status`).subscribe(result => {
        console.log(result);
        observer.next(result);
        observer.complete();
      })
    })
  }

  update(params):Observable<any> {
    return Observable.create(observer => {
      this.http.put('/orders', params).subscribe(result => {
        observer.next(result);
        observer.complete();
      })
    })
  }

  destroy():Observable<any> {
    return Observable.create(observer => {
      this.http.delete('/orders').subscribe(result => {
        observer.next(result);
        observer.complete();
      })
    })
  }
}