import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import templateString from './slot.modal.html';


@Component({
  selector: 'app-slot-modal',
  template: templateString
})
export class SlotModalComponent implements OnInit {
  @Output() slotChanged = new EventEmitter<string>();
  constructor(    
    private readonly bsModalRef: BsModalService
  ) {
    
  }

  ngOnInit(): void {
  }

  slotSelected() {
    this.slotChanged.emit();
    this.bsModalRef.hide();

  }
}